import React from 'react';
import PropTypes from 'prop-types';
import 'materialize-css';
import styled from 'styled-components';
import { Navbar, NavItem } from 'react-materialize';
import { LabelMonaco } from '../atoms/label.jsx';
import { COLOR, SIZE, SCREEN_MEDIA, LABEL } from '../../utils/constants.js';
import SVG from 'react-inlinesvg';

import PathLogo from '../../images/img_logo.svg';
import PathBurger from '../../icons/ic_burger.svg';


const Logo = styled(SVG)`
    fill: ${COLOR.white};
    height: 35px;
    top: 10px;
    position: relative;
`;
const Burger = styled(SVG)`
    fill: ${COLOR.white};
    width: 20px;
    position: relative;
`;

const logo_dom = <a href='/'><Logo src={PathLogo}></Logo></a>
const burger_dom = <Burger src={PathBurger}></Burger>;


const NavbarStyled = styled(Navbar)`
    @media only screen and ${SCREEN_MEDIA.small} {
        padding: 0 0px;
        background-color: ${COLOR.primary};
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        padding: 0 10px;
        background-color: ${COLOR.primary};
    }
    @media only screen and ${SCREEN_MEDIA.long} {
        padding: 0 100px;
        background-color: ${COLOR.primary};
    }
    @media only screen and ${SCREEN_MEDIA.xlong} {
        padding: 0 100px;
        background-color: ${COLOR.primary};
    }
`;

const NavItemStyled = styled(NavItem)`
    background-color: ${props => props.selected ? COLOR.primaryDark : COLOR.primary};
`;

const NavItemLabelStyled = styled(LabelMonaco)`
    font-size: ${SIZE.caption};
    color: ${COLOR.white};
`;

const Header = (props) => {

    var about_selected = false;
    var contact_selected = false;
    var tech_selected = false;

    switch (props.selected) {
        case LABEL.about:
            about_selected = true;
            break;
        case LABEL.contact:
            contact_selected= true;
            break;
        case LABEL.tech:
            tech_selected= true;
            break;
    }
    

    return (
        <NavbarStyled 
            alignLinks='right'
            brand={logo_dom}
            fixed={true}
            menuIcon={burger_dom}>

            <NavItemStyled
                href='/'
                selected={about_selected}>
                <NavItemLabelStyled>
                    {LABEL.about}
                </NavItemLabelStyled>
            </NavItemStyled>

            <NavItemStyled
                href='/tech'
                selected={tech_selected}>
                <NavItemLabelStyled>
                    {LABEL.tech}
                </NavItemLabelStyled>
            </NavItemStyled>

        </NavbarStyled>
    );
    
}

Header.propTypes = {
    selected: PropTypes.string
}

export default Header;