import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TextInput as MatTextInput } from 'react-materialize';

const Content = styled.div`
    display: ${props => props.display ? props.display : 'flex'};
    margin: ${props => props.margin ? props.margin : '0 0'};
`;

const TextInput = (props) => {
    return (
        <Content 
            margin={props.margin}
            display={props.display}>
            <MatTextInput 
                id={props.id} 
                type={props.type}
                label={props.label}
                placeholder={props.placeholder}
                name={props.name}
                value={props.value}
                onChange={props.onChange} />
        </Content>
    );
}

TextInput.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    margin: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    display: PropTypes.string,
    onChange: PropTypes.func 
}

export default TextInput;