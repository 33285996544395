import React from 'react';
import { Container, Col } from 'react-materialize';
import { SIZE, LABEL, COLOR } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import Banner from '../organism/banner.jsx';
import CardContent from '../molecules/cardcontent.jsx';
import Divider from '../atoms/divider';
import Bullets from '../molecules/bullets.jsx';

import PathImgCoding from '../../images/img_coding.jpg';
import PathIcAndroidColored from '../../icons/ic_android_colored.svg';
import PathIcWorldSvg from '../../icons/ic_world.svg';
import PathIcGitColored from '../../icons/ic_git_colored.svg';



const BodyTech = () => {
    return(
        <div>
            <Banner img_path={PathImgCoding} title={LABEL.tech}/>

            <Container>
                <Row margin='60px 0 0 0'>
                    <CardContent
                        height='120px'
                        ic_path={PathIcAndroidColored}
                        ic_height='90px'
                        title='Android'
                        title_size={SIZE.subtitle}
                        alignment='center'/>
                </Row>
                <Row className='valign-wrapper' margin='40px 0 0 0'>
                    <Col s={12} m={12} l={12} xl={12} offset='s1 m2 l3 xl4'>
                        <Bullets margin='30px auto'
                            items={[
                                { item: "Android Studio IDE" },
                                { item: "Kotlin & Java Language" },
                                { item: "Model-View-Viewmodel (MVVM) Architecture" },
                                { item: "Repository Design Pattern" },
                                { item: "Android Room Library" },
                                { item: "Navigation Component Library" },
                                { item: "View Binding Library" },
                                { item: "Coroutines Library" },
                                { item: "Koin Library" },
                                { item: "Retrofit Library" }
                            ]}/>
                    </Col>
                </Row>

                <Divider margin='60px 0 0 0'/>

                <Row margin='60px 0 0 0'>
                    <CardContent
                        height='120px'
                        ic_path={PathIcWorldSvg}
                        ic_color={COLOR.secondaryClear}
                        ic_height='90px'
                        title='Web'
                        title_size={SIZE.subtitle}
                        alignment='center'/>
                </Row>
                <Row className='valign-wrapper' margin='40px 0 0 0'>
                    <Col s={12} m={12} l={12} xl={12} offset='s1 m2 l3 xl4'>
                        <Bullets margin='30px auto'
                            items={[
                                { item: "Visual Studio Code IDE" },
                                { item: "React Library" },
                                { item: "React PropTypes" },
                                { item: "React Materialize Library" },
                                { item: "React Router DOM Library" },
                                { item: "Styled Component Library" },
                                { item: "JavaScript Language" },
                                { item: "jQuery Language" }
                            ]}/>
                    </Col>
                </Row>
                
                <Divider margin='60px 0 0 0'/>

                <Row margin='60px 0 0 0'>
                    <CardContent
                        height='120px'
                        ic_path={PathIcGitColored}
                        ic_height='90px'
                        title='Support'
                        title_size={SIZE.subtitle}
                        alignment='center'/>
                </Row>
                <Row className='valign-wrapper' margin='40px 0 0 0'>
                    <Col s={12} m={12} l={12} xl={12} offset='s1 m2 l3 xl4'>
                        <Bullets margin='30px 0 30px 0'
                            items={[
                                { item: "Git Version Control" },
                                { item: "Firebase" },
                                { item: "Atlassian Sourcetree" },
                                { item: "Atlassian Bitbucket" },
                                { item: "Atlassian Jira" },
                                { item: "Atlassian Confluence" },
                                { item: "Postman" },
                                { item: "Zeplin" }
                            ]}/>
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default BodyTech;