import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from './components/organism/header.jsx';
import Footer from './components/organism/footer.jsx';
import BodyAbout from './components/templates/bodyabout.jsx';
import BodyTech from './components/templates/bodytech.jsx';
import { Root } from './utils/global.js';
import { LABEL } from './utils/constants.js';
import { 
    FontMonaco,
    FontSailecRegular, 
    FontSailecMedium, 
    FontSailecBold, 
    FontSailecLight, 
    FontHarmonyRegular, 
    FontHarmonyLite } from './utils/fonts.js';

const App = () => {
     return(
     <div>
          <Root/>
          <FontMonaco/>
          <FontSailecRegular/>
          <FontSailecMedium/>
          <FontSailecBold/>
          <FontSailecLight/>
          <FontHarmonyRegular/>
          <FontHarmonyLite/>
          
          <Router>
            <Switch>

                <Route 
                    exact path='/'>
                    <Header selected={LABEL.about}/>
                    <BodyAbout/>
                </Route>

                <Route 
                    path='/tech'>
                    <Header selected={LABEL.tech}/>
                    <BodyTech/>
                </Route>

            </Switch>
        </Router>

        <Footer/>
     </div>
     );
}

export default App;