import React from 'react';
import styled from 'styled-components';
import { Footer as Feet } from 'react-materialize';
import { COLOR } from '../../utils/constants.js';
import TextButton from '../atoms/textbutton.jsx';
import SVG from 'react-inlinesvg';

import PathLogo from '../../images/img_logo.svg';
import PathIcLinkedin from '../../icons/ic_linkedin.svg';
import PathIcBitbucket from '../../icons/ic_bitbucket.svg';
import PathIcGithub from '../../icons/ic_github.svg';
import PathBg from '../../images/img_03.jpg'

const url_linkedin = "https://www.linkedin.com/in/david-pasache-8b0928142/";
const url_bitbucket = "https://bitbucket.org/DavidPL98/";
const url_github = "https://github.com/David2289";

const current_year = new Date().getFullYear();
const copyright_label = "Copyright \xA9" + current_year.toString() + " Todos los derechos reservados";


const List = styled.ul`
    margin-top: 40px;
    li {
        margin-bottom: 20px;
        display: flex; // To avoid purple padding on <a> tag
    }
`;

const links = 
<List>
    <li>
        <TextButton 
            href={url_linkedin}
            target="_blank"
            ic_path={PathIcLinkedin}
            ic_color={COLOR.white}
            text_color={COLOR.white}>
                Linkedin
        </TextButton>
    </li>
    <li>
        <TextButton 
            href={url_bitbucket}
            target="_blank"
            ic_path={PathIcBitbucket}
            ic_color={COLOR.white}
            text_color={COLOR.white}>
                Bitbucket
        </TextButton>
    </li>
    <li>
        <TextButton 
            href={url_github}
            target="_blank"
            ic_path={PathIcGithub}
            ic_color={COLOR.white}
            text_color={COLOR.white}>
                Github
        </TextButton>
    </li>
</List>;


const FeetStyled = styled(Feet)`
    margin: 100px 0 0 0;
    background-image: url(${PathBg});
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    z-index: 0;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
        background: ${COLOR.primary};
        opacity: 0.9;
    }
`;

const LogoContent = styled.div`
    margin: 60px 0 60px 0;
`;

const Logo = styled(SVG)`
    fill: ${COLOR.white};
    margin: auto;
    height: 80px;
`;

const Footer = () => (
    <FeetStyled
        copyrights={copyright_label}
        links={links} >
        <LogoContent className='valign-wrapper'>
            <Logo src={PathLogo} />
        </LogoContent>
    </FeetStyled>
);

export default Footer;