import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { LabelMonaco } from '../atoms/label.jsx';
import { getExtension } from '../../utils/utils.js'


const Content = styled.div`
    display: flex;
    float: ${props => props.float ? props.float : 'left'};
    margin: ${props => props.margin ? props.margin : '0 0'};
`;

//TODO: Check height fails when text has two lines
const IconContent = styled.div`
    width: ${props => props.ic_size ? props.ic_size : '25px'};
    height: ${props => props.ic_size ? props.ic_size : '25px'};
    margin-left: ${props => props.separation ? props.separation : '15px'};
    margin-right: ${props => props.separation ? props.separation : '15px'};
    ${props => props.valign ? 'none' : 'position: relative;'}
    ${props => props.valign ? 'none' : 'top: ' + props.ic_top};
`;

const SVGStyled = styled(SVG)`
    width: ${props => props.ic_size ? props.ic_size : '25px'};
    height: ${props => props.ic_size ? props.ic_size : '25px'};
    display: block;
    ${props => props.ic_color ? 'fill: ' + props.ic_color : 'none'};
`;

const Icon = styled.img`
    height: 100%;
    width: 100%;
    display: block;
`;


const TextIcon = (props) => {

    const icAlign = props.ic_align ? props.ic_align : 'left';
    const margin = '0 ' + props.separation + ' 0 ' + props.separation;

    var icContent
    if (props.ic_path) {
        const icExtension = getExtension(props.ic_path)
        icContent = (icExtension === 'svg' && props.ic_color) ? 
        <IconContent
            ic_size={props.ic_size}
            ic_top={props.ic_top}
            separation={props.separation}
            valign={props.valign} >
            <SVGStyled 
                ic_size={props.ic_size}
                src={props.ic_path}
                ic_color={props.ic_color}
                />
        </IconContent>
        :
        <IconContent
            ic_size={props.ic_size}
            ic_top={props.ic_top}
            separation={props.separation}
            valign={props.valign} >
            <Icon 
                src={props.ic_path}
                ic_color={props.ic_color}
                />
        </IconContent>;
    } else {
        icContent = null
    }
    
    const label = 
        <LabelMonaco
            text_color={props.text_color}
            text_size={props.text_size}
            margin={margin} >
            {props.children}
        </LabelMonaco>;

    if (icAlign === 'right') {
        return (
            <Content
                className={props.valign ? 'valign-wrapper' : 'none'}
                float={props.float}
                margin={props.margin}>
                { label }
                { icContent }
            </Content>
        )
    } 
    else {
        return (
            <Content
                className={props.valign ? 'valign-wrapper' : 'none'}
                float={props.float}
                margin={props.margin}>
                { icContent }
                { label }
            </Content>
        )
    }
}

TextIcon.defaultProps = {
    valign: true,
    ic_top: '8px'
}

TextIcon.propTypes = {
    ic_path: PropTypes.string,
    ic_color: PropTypes.string,
    ic_size: PropTypes.string,
    ic_align: PropTypes.string,
    ic_top: PropTypes.string,
    text_color: PropTypes.string,
    text_size: PropTypes.string,
    margin: PropTypes.string,
    separation: PropTypes.string,
    float: PropTypes.string,
    valign: PropTypes.bool
}

export default TextIcon;