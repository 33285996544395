import React, { useRef } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import TextInput from '../atoms/textinput.jsx';
import TextArea from '../atoms/textarea.jsx';
import Button from '../atoms/button.jsx';
import { LabelSailecMedium } from '../atoms/label.jsx';
import { COLOR, SIZE, KEY } from '../../utils/constants.js';

const Containter = styled.div`
    overflow: auto; // To cover height with contents
    padding: 30px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.02), 
                0 2px 4px rgba(0,0,0,0.02), 
                0 4px 8px rgba(0,0,0,0.02), 
                0 8px 16px rgba(0,0,0,0.02),
                0 16px 32px rgba(0,0,0,0.02);
`;

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(KEY.emailJsService, KEY.emailJsTemplate, form.current, KEY.emailJsUserId)
            .then((result) => {
                console.log("message sent");
                console.log(result.text);
                alert("Message received");
            }, (error) => {
                console.log("message error");
                console.log(error.text);
            });
        e.target.reset();
    }

    return(
        <Containter>
            <LabelSailecMedium 
                margin='10px 0 0 0' 
                text_size={SIZE.title}>
                Contact
            </LabelSailecMedium>

            <form ref={form} onSubmit={sendEmail}>
            <TextInput 
                id='email'
                name='email'
                type='email' 
                placeholder='Email'
                margin='20px 0'
                onChange={ () => {} }/>
            <TextArea
                id='message'
                name='message'
                type='text'
                placeholder='Message'
                margin='20px 0'
                onChange={ () => {} }/>
            <Button
                type='submit'
                bg_color={ COLOR.primary }
                text_color={ COLOR.white }
                margin='10px 0 0 0'
                float='right'
                onChange={ () => {} }>
                Send
            </Button>
            </form>
            
        </Containter>
    );
}

export default Contact;