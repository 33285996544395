import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextIcon from './texticon.jsx';


const AContent = styled.a`
    float: ${props => props.float ? props.float : 'left'}; 
    margin: ${props => props.margin ? props.margin : '0 0'};
    cursor: pointer;
`;


const TextButton = (props) => {
    return (
        <AContent 
            className='valign-wrapper'
            href={props.href}
            target={props.target}
            float={props.float}
            margin={props.margin}
            onClick={props.onClick}>
            <TextIcon
                ic_path={props.ic_path}
                ic_color={props.ic_color}
                ic_size={props.ic_size}
                ic_align={props.ic_align}
                text_color={props.text_color}
                text_size={props.text_size}
                separation={props.separation}>
                {props.children}
            </TextIcon>
        </AContent>
    );
}

TextButton.propTypes = {
    ic_path: PropTypes.string,
    ic_color: PropTypes.string,
    ic_size: PropTypes.string,
    ic_align: PropTypes.string,
    text_color: PropTypes.string,
    text_size: PropTypes.string,
    margin: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    separation: PropTypes.string,
    float: PropTypes.string, 
    onClick: PropTypes.func
}

export default TextButton;