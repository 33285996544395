import React from 'react';
import styled from 'styled-components';
import { Container, Col, Parallax } from 'react-materialize';
import { COLOR, SIZE, SCREEN_MEDIA } from '../../utils/constants.js';
import Row from '../organism/row.jsx';
import RowInfo from '../organism/rowinfo.jsx';
import TitleSect from '../atoms/titlesect.jsx';
import CardContent from '../molecules/cardcontent.jsx';
import Divider from '../atoms/divider.jsx';
import Contact from '../organism/contact.jsx';

import PathImg01 from '../../images/img_01.jpg';
import PathImg02 from '../../images/img_02.jpg';
import PathImgCoding from '../../images/img_coding.jpg';

import PathIcAndroid from '../../icons/ic_android.svg';
import PathIcWorld from '../../icons/ic_world.svg';
import PathIcKotlin from '../../icons/ic_kotlin_black.svg';
import PathIcReact from '../../icons/ic_react.svg';
import PathIcGit from '../../icons/ic_git.svg';
import PathIcAtlassian from '../../icons/ic_atlassian.svg';

import PathIcBriefcase from '../../icons/ic_briefcase.svg';
import PathIcCompany from '../../icons/ic_company.svg';
import PathIcGear from '../../icons/ic_gear.svg';


const ParallaxStyled = styled(Parallax)`
    height: 800px;
    margin: 60px 0;
    // Cover and center background image: //
    background-position: 50% 50%; /*El cuadro ajustado se puede centrar horizontal y verticalmente*/
    background-size: cover; /*El cuadro de la imagen se ajusta a la dimensión más pequeña del div con el objetivo de cubrirla*/
    background-repeat: no-repeat;
    // To make the overlay: //
    position: relative;
    z-index: -1;
    &::after {
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        background: ${COLOR.primary};
        opacity: 0.8;
    }
    @media only screen and ${SCREEN_MEDIA.small} {
        height: 1750px;
    }
    @media only screen and ${SCREEN_MEDIA.medium} {
        height: 1000px;
    }
`;

const ParallaxBody = styled(Container)`
    position: relative;
    z-index: 1;
`;

const BodyAbout = () => {
    return (
        <div>

            <Container>
                <RowInfo
                    margin='80px 0'
                    img_path={PathImg01}
                    img_right={false}
                    title='About me'
                    descs={["Hey, I'm Rogers David.", "I'm a telecommunication engineer graduated in Catalunya-Spain. I started my professional journey in 2017 working as a Web developer in UPCnet company. Currently I have more experience in Android development working in different companies, I intent to work on iOS applications in the future to expand my knowledges.", "My passion and focus is to do helpfull applications to make people's life better in different kind of areas."]}/>

            </Container>

            <ParallaxStyled
                image={<img alt="" src={PathImgCoding}/>}
                options={{responsiveThreshold: 0}}
                children={
                    <ParallaxBody>
                        <Row>
                            <TitleSect color={COLOR.white}>Experience</TitleSect>
                        </Row>
                        <Row>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcAndroid} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Android' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["Most of my experience is based on applications for Android environment"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcWorld} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Web' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["I enjoy doing web projects for front-end side"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcKotlin} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Kotlin' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["In my opinion, the best POO language to develop Android applications"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcReact} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='React' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["Doing components now is possible with React Library"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>

                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcGit} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Git' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["I handled each project with Git version control"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>

                            <Col s={12} m={6} l={4} xl={4}>
                                <CardContent 
                                    height='250px'
                                    ic_path={PathIcAtlassian} 
                                    ic_height='50px'
                                    ic_color={COLOR.white}
                                    title='Atlassian' 
                                    title_size={SIZE.title}
                                    title_color={COLOR.white}
                                    descs={["It's easier to manage the projects with Atlassian products"]}
                                    descs_size={SIZE.caption}
                                    desc_color={COLOR.white}
                                    alignment='center'
                                    margin='0px 20px'/>
                            </Col>
                        </Row>
                    </ParallaxBody>
                }
            />

            <Container>
                <Row margin='40px 0 40px 0'>
                    <Col s={12} m={12} l={4} xl={4}>
                        <CardContent 
                            ic_path={PathIcBriefcase} 
                            ic_height='50px'
                            ic_color={COLOR.primaryDark}
                            title='5'
                            descs={['Work experience years']} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>

                    <Col s={12} m={12} l={4} xl={4}>
                        <CardContent 
                            ic_path={PathIcCompany} 
                            ic_height='50px'
                            ic_color={COLOR.primaryDark}
                            title='5'
                            descs={["Job companies"]} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>

                    <Col s={12} m={12} l={4} xl={4}>
                        <CardContent 
                            ic_path={PathIcGear} 
                            ic_height='50px'
                            ic_color={COLOR.primaryDark}
                            title='10'
                            descs={['Projects']} 
                            alignment='center'
                            margin='30px 0px'/>
                    </Col>
                </Row>

                <Divider/>

                <RowInfo
                    margin='80px 0'
                    img_path={PathImg02}
                    img_right={false}
                    title='Projects'
                    descs={["I've worked in projects for different kind of Information Technology business..."]}
                    bullet_items={[
                        { item: "Data mining projects for a local university." }, 
                        { item: "Fintech project to manage bank accounts" }, 
                        { item: "Banking projects for local banks."}, 
                        { item: "International Hotel Booking." }, 
                        { item: "Biometric technology to session up." }, 
                        { item: "School corporate website." }, 
                        { item: "Data mining for e-commerces." }]}/>

                <Divider/>

                <Row className='valign-wrapper' margin='40px 0 0 0'>
                    <Col s={12} m={12} l={12} xl={8} offset='xl2'>
                        <Contact/>
                    </Col>
                </Row>
                
            </Container>
            
        </div>
    );
}

export default BodyAbout;